import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_living_earth_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const LivingEarthUnit1 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 1',
    linkLabel: 'LIVING EARTH COURSE',
    to: '/classroom-instruction/living-earth',
  };

  const {
    leCommunicatingGeneticTestReport,
    leGeneticCounseling,
    leGeneticDetectivesIntro,
    leGeneticDetectivesJournal,
    leGenetics,
    leGeneticMutations,
    leInheritedDiseases,
    // leStudentInteractive, // removing interactive until V2
    leTreatingInheritedDisease,
  } = getKeyedResources(data);

  return (
    <Layout title="Living Earth Unit 1">
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-living-earth-unit1.jpg"
        guidSrc="04161e1c-264d-4de9-8e77-dd38e165fb57"
        posterSrc={'unit-1-living-earth-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'purple'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">
          Genetic Detectives: Investigating Inherited Diseases
        </h1>
        <p className="pb-2">
          How do you counsel a patient on their risk of genetic disease while
          considering their health literacy?
        </p>
      </Hero>
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>LIVING EARTH | UNIT 1</div>
            <Link
              to="/classroom-instruction/living-earth/unit-2"
              className="course__unit-indicator-link"
            >
              UNIT 2 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Students take on the role of a genetic counselor in order to
                  examine inherited diseases.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>6 week Unit</li>
                <li>45 minute classes</li>
                <li>Built in flex days</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project-based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>1 Project Based Introduction </li>
                <li>6 Core Lessons & Labs</li>
                <li>6 Class Presentations </li>
                <li>1 Student Project-based solution </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                and presentations that empower educators to engage all students.
                These lesson bundles can be downloaded, for the use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-LE-U1.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 53.5 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leGeneticDetectivesIntro.tophat}
                tophatClass={`${leGeneticDetectivesIntro.page}__resource-tophat`}
                ribbon={leGeneticDetectivesIntro.ribbon}
                ribbonClass={`${leGeneticDetectivesIntro.page}__resource-ribbon`}
                img=""
                duration={leGeneticDetectivesIntro.duration}
                subject={leGeneticDetectivesIntro.subject}
                subjectClass={`${leGeneticDetectivesIntro.page}__resource-subject`}
                title={leGeneticDetectivesIntro.title}
                description={leGeneticDetectivesIntro.description}
                actions={leGeneticDetectivesIntro.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leInheritedDiseases.tophat}
                tophatClass={`${leInheritedDiseases.page}__resource-tophat`}
                ribbon={leInheritedDiseases.ribbon}
                ribbonClass={`${leInheritedDiseases.page}__resource-ribbon`}
                img=""
                duration={leInheritedDiseases.duration}
                subject={leInheritedDiseases.subject}
                subjectClass={`${leInheritedDiseases.page}__resource-subject`}
                title={leInheritedDiseases.title}
                description={leInheritedDiseases.description}
                actions={leInheritedDiseases.actions}
              />
            </Column>
            {/* Removing interactive until V2 */}
            {/* <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leStudentInteractive.tophat}
                tophatClass={`${leStudentInteractive.page}__resource-tophat`}
                ribbon={leStudentInteractive.ribbon}
                ribbonClass={`${leStudentInteractive.page}__resource-ribbon`}
                img=""
                duration={leStudentInteractive.duration}
                subject={leStudentInteractive.subject}
                subjectClass={`${leStudentInteractive.page}__resource-subject`}
                title={leStudentInteractive.title}
                description={leStudentInteractive.description}
                actions={leStudentInteractive.actions}
              />
            </Column> */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leGenetics.tophat}
                tophatClass={`${leGenetics.page}__resource-tophat`}
                ribbon={leGenetics.ribbon}
                ribbonClass={`${leGenetics.page}__resource-ribbon`}
                img=""
                duration={leGenetics.duration}
                subject={leGenetics.subject}
                subjectClass={`${leGenetics.page}__resource-subject`}
                title={leGenetics.title}
                description={leGenetics.description}
                actions={leGenetics.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leGeneticMutations.tophat}
                tophatClass={`${leGeneticMutations.page}__resource-tophat`}
                ribbon={leGeneticMutations.ribbon}
                ribbonClass={`${leGeneticMutations.page}__resource-ribbon`}
                img=""
                duration={leGeneticMutations.duration}
                lab={leGeneticMutations.lab}
                subject={leGeneticMutations.subject}
                subjectClass={`${leGeneticMutations.page}__resource-subject`}
                title={leGeneticMutations.title}
                description={leGeneticMutations.description}
                actions={leGeneticMutations.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leTreatingInheritedDisease.tophat}
                tophatClass={`${leTreatingInheritedDisease.page}__resource-tophat`}
                ribbon={leTreatingInheritedDisease.ribbon}
                ribbonClass={`${leTreatingInheritedDisease.page}__resource-ribbon`}
                img=""
                duration={leTreatingInheritedDisease.duration}
                subject={leTreatingInheritedDisease.subject}
                subjectClass={`${leTreatingInheritedDisease.page}__resource-subject`}
                title={leTreatingInheritedDisease.title}
                description={leTreatingInheritedDisease.description}
                actions={leTreatingInheritedDisease.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leCommunicatingGeneticTestReport.tophat}
                tophatClass={`${leCommunicatingGeneticTestReport.page}__resource-tophat`}
                ribbon={leCommunicatingGeneticTestReport.ribbon}
                ribbonClass={`${leCommunicatingGeneticTestReport.page}__resource-ribbon`}
                img=""
                duration={leCommunicatingGeneticTestReport.duration}
                subject={leCommunicatingGeneticTestReport.subject}
                subjectClass={`${leCommunicatingGeneticTestReport.page}__resource-subject`}
                title={leCommunicatingGeneticTestReport.title}
                description={leCommunicatingGeneticTestReport.description}
                actions={leCommunicatingGeneticTestReport.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leGeneticCounseling.tophat}
                tophatClass={`${leGeneticCounseling.page}__resource-tophat`}
                ribbon={leGeneticCounseling.ribbon}
                ribbonClass={`${leGeneticCounseling.page}__resource-ribbon`}
                img=""
                duration={leGeneticCounseling.duration}
                lab={leGeneticCounseling.lab}
                subject={leGeneticCounseling.subject}
                subjectClass={`${leGeneticCounseling.page}__resource-subject`}
                title={leGeneticCounseling.title}
                description={leGeneticCounseling.description}
                actions={leGeneticCounseling.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leGeneticDetectivesJournal.tophat}
                tophatClass={`${leGeneticDetectivesJournal.page}__resource-tophat`}
                ribbon={leGeneticDetectivesJournal.ribbon}
                ribbonClass={`${leGeneticDetectivesJournal.page}__resource-ribbon`}
                img=""
                duration={leGeneticDetectivesJournal.duration}
                subject={leGeneticDetectivesJournal.subject}
                subjectClass={`${leGeneticDetectivesJournal.page}__resource-subject`}
                title={leGeneticDetectivesJournal.title}
                description={leGeneticDetectivesJournal.description}
                actions={leGeneticDetectivesJournal.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 1',
            link: '/classroom-instruction/living-earth/unit-1',
          }}
          next={{
            name: 'Unit 2',
            link: '/classroom-instruction/living-earth/unit-2',
          }}
          previous={{
            name: 'Overview',
            link: '/classroom-instruction/living-earth',
          }}
        />
      </Section>
    </Layout>
  );
};

export default LivingEarthUnit1;

export const query = graphql`
  query LivingEarthUnit1Resources {
    allResourcesJson(
      filter: { page: { eq: "living-earth" }, unit: { eq: 1 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
